.wpp-container {
  text-align: center;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

.help-text {
  font-size: 16px;
  font-weight: bold;
}

.arrow {
  margin: 10px 10px;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

.whatsapp-button {
  display: inline-flex;
  align-items: center;
  background-color: #25d366;
  color: #fff;
  padding: 20px 200px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}


.whatsapp-button:hover {
  background-color: #1ebe57;
}

.whatsapp-icon {
  margin-right: 8px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .whatsapp-button {
    padding: 15px 100px; 
    font-size: 14px;     
  }
}

@media (max-width: 480px) {
  .whatsapp-button {
    padding: 10px 50px;  
    font-size: 12px;     
  }
}