.form-header-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.header {
  background: linear-gradient(to bottom, #f57c00, #ffca28);
  color: white;
  padding: 30px 20px;
  border-radius: 5px;
  text-align: center;
}

footer {
  background-color: #ffffff;
  border-radius: 10px; 
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333333;
}

.footer .logo {
  margin-bottom: 15px;
}

.footer p {
  margin: 10px 0;
  font-size: 11px;
}

.logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.form-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}

.logo {
  max-width: 30%; 
  height: auto; 
  display: block; 
  margin: 0 auto;
  border-radius: 10px; 
}

button {
  width: 100%;
  padding: 15px;
  background-color: #f57c00;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #e65100;
}

.also-text {
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #f57c00, #ffca28);
  /* background-color: #f57c00; */
  padding: 20px 20px;
  margin: 30px auto;
  border-radius: 5px;
}

.image-money {
  width: 100%; 
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.1);
  margin: 30px 0;
}

.img-class {
  width: 100%; 
  height: 20%;   
}

.hand {
  margin: 10px 10px;
  animation: bounce 1s infinite;
}


@media (max-width: 768px) {
  p {
    font-size: 14px;     
  }
}

@media (max-width: 480px) {
  p {
    font-size: 12px;     
  }
}